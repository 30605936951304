import React from 'react'
import { X } from 'react-feather';
import cx from 'classnames';

import styles from './Modal.module.scss';

const Modal = ({ children, closeModal, wide }) => {
    return (
        <div
            className={styles.modal}
            onClick={(event) => {
                if (event.target.classList.contains('blanker')) {
                    closeModal();
                }
            }}
        >
            <div className={cx('blanker', styles.modalBlanker)}>
                <div className={cx(styles.modalContent, { [styles.modalContentWide]: wide })}>
                    <button className={styles.modalCloseButton} onClick={() => closeModal()}><X /></button>
                    <div className={styles.modalContentInner}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal