import { gql } from 'apollo-boost';

const studentEntryQuery = `
id
castedCounter
feedback
status
submittedAt
teacherEdit
text
winner
seenWinner
author {
    id
    displayName
    firstName
    lastName
    name
}
vocabularyWordsUsed
`;

const chapterQuery = `
id
content
description
guidance
orderid
status
title
classBook {
    id
    isSingleChapter
    classPack {
        id
        isWritingActivity
    }
    studentsClass {
        id
        name    
        teachers {
            id
            displayName
        }
    }
    bookTemplate {
        id
        coverUrl
        videoUrl
    }
}
currentStudentEntry {
    ${studentEntryQuery}
}
currentStudentNextEntryToStarCast {
    id
    text
}
studentEntries {
    id
    winner
    seenWinner
    teacherEdit
    text
    author {
        id
        name
    }
}
vocabularyWords
`;

export const GET_CLASS_BOOK = gql`
query classBook($classBookId: ID!) {
    classBook(classBookId: $classBookId) {
        id
        currentChapter
        isSingleChapter
        status
        title
        bookTemplate {
            id
            name
            coverUrl
            videoUrl
            resources {
                id
                name
                url
                resourceType
            }
        }
        classPack {
            id
            isWritingActivity
        }
        chapters {
            ${chapterQuery}
        }

        studentsClass {
            id
            name
            teachers {
                id
                displayName
            }
            school {
                id
                name
            }
        }
    }
}
`;

export const GET_STUDENT_ENTRY = gql`
query studentEntry($id: ID!) {
    studentEntry(id: $id) {
        ${studentEntryQuery}
    }
}
`;

export const SAVE_DRAFT_MUTATION = gql`
mutation saveChapterEntry($bookChapterStudentEntryId: ID!, $text: String!) {
    saveChapterEntry(bookChapterStudentEntryId: $bookChapterStudentEntryId, text: $text) {
        bookChapterStudentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}`;

export const HAND_IN_ENTRY_MUTATION = gql`
mutation handInChapterEntry($bookChapterStudentEntryId: ID!, $text: String!) {
    handInChapterEntry(bookChapterStudentEntryId: $bookChapterStudentEntryId, text: $text) {
        bookChapterStudentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}`;

export const CAST_MUTATION = gql`
mutation starCastChapterEntry($bookChapterStudentEntryId: ID!, $stars: Int!) {
    starCastChapterEntry(bookChapterStudentEntryId: $bookChapterStudentEntryId, stars: $stars) {
        bookChapter {
            ${chapterQuery}
        }
        errors
    }
}`;


export const SEE_CHAPTER_WINNER = gql`
mutation seeChapterWinner($bookChapterStudentEntryId: ID!) {
    seeChapterWinner(bookChapterStudentEntryId: $bookChapterStudentEntryId) {
        bookChapterStudentEntry {
            ${studentEntryQuery}
        }
        errors
    }
}`;
