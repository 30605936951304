import React, { Fragment, useState } from 'react';
import { Lock } from 'react-feather';
import { useEffectOnce } from 'react-use';
import _ from 'lodash';

import { ENTRY_STATUS } from '@axeedge/go-shared-utils';

import EditorJsOutput from '../../../../../../components/EditorJsOutput';
import Guidance from '../Guidance';
import Header from '../Header';
import { updateEntry, highlightEntry } from '../../../../services/utils';
import VocabModule from '../VocabModule';

import styles from '../../Chapter.module.scss';

const EMPTY_ENTRY = {
    blocks: []
}

const Writing = ({ chapter, resources = null }) => {

    const [entry, setEntry] = useState({
        text: JSON.parse(chapter.currentStudentEntry.text) || EMPTY_ENTRY,
        wordCount: 0
    });


    useEffectOnce(() => {
        if (chapter.currentStudentEntry.text) {
            updateEntry(entry.text, setEntry);
        }
    });


    const getGuidanceTitle = () => {
        if (chapter.currentStudentEntry.status === ENTRY_STATUS.submitted) {
            return 'Fantastic!';
        }
        if (chapter.currentStudentEntry.status === ENTRY_STATUS.revisionRequested) {
            return 'Feedback';
        }
        return 'Instructions';
    }

    const getGuidanceText = () => {
        if (chapter.currentStudentEntry.status === ENTRY_STATUS.submitted) {
            return 'Your work has now been sent to your teacher. Well done!';
        }
        if (chapter.currentStudentEntry.status === ENTRY_STATUS.revisionRequested) {
            return chapter.currentStudentEntry.feedback;
        }
        // return chapter.guidance ? chapter.guidance : (chapter.classBook.classPack.isWritingActivity ? chapter.description : 'No instructions added');
        return chapter.guidance ? chapter.guidance :  'No instructions added';

    }

    return (
        <Fragment>
            <div className={styles.chapter}>
                <div className={styles.chapterEntry}>
                    <div className={styles.chapterEntryContent}>
                        <Header title={chapter.title} author={chapter.currentStudentEntry.author.name} />
                        {
                            chapter.currentStudentEntry.text ?
                            <EditorJsOutput data={highlightEntry(entry.text, chapter.vocabularyWords)} /> :
                            <p>Sorry, you didn't write an entry for this chapter</p>
                        }

                    </div>
                </div>

                <div className={styles.chapterSide}>
                    <Guidance isFeedback={chapter.currentStudentEntry.status === ENTRY_STATUS.revisionRequested} title={getGuidanceTitle()} text={getGuidanceText()} resources={resources} />
                    {chapter.currentStudentEntry.text && <VocabModule entry={entry.text} vocab={chapter.vocabularyWords} />}

                    <div className={styles.writingClosed}>
                        <Lock className={styles.writingClosedIcon} size='50' />
                        <p className={styles.writingClosedText}>Writing Has Ended</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Writing;
