import React, { useState, Fragment } from 'react';
import { Button } from '@axeedge/go-pupil-components';
import { BOOK_STATUS } from '@axeedge/go-shared-utils';
import noCover from '../../../../images/no-cover.png';
import Guidance from '../Chapter/components/Guidance';
import styles from './BookInfo.module.scss';
import styles2 from '../Chapter/Chapter.module.scss';

const BookInfo = ({ book, setSelectedChapter }) => {

    const [completed] = useState(book.status >= BOOK_STATUS.completed)
    const getGuidanceText = () => {

        // if (book.isSingleChapter && book.classPack.isWritingActivity) {
        //     return (
        //         <>
        //             <p>Follow your teacher's instructions carefully for this piece of writing and make sure you try your best!'</p>
        //             <p>Good luck!</p>
        //         </>
        //     )
        // }
        // if (book.isSingleChapter && !book.classPack.isWritingActivity) {
        if (book.isSingleChapter) {
            return (
                <>
                    <p>For this book follow your teacher’s instructions carefully and don’t forget your audience!</p>
                    <p>You will be StarCasting your classmates’ writing to find out the overall winning piece for the published book.</p>
                    <p>Don't forget- your writing will be included in this book... you're about to become a published writer!</p>
                    <p className="u-mb-0">Good luck!</p>
                </>
            )
        }
        return (
            <>
                <p>You and your class will complete each chapter of this book and at the end you’ll each receive a printed copy!</p>
                <p className="u-mb-0">The book will feature the full story plus your own alternative ending.</p>
            </>
        )

    }



    return (
        <div className={styles.book}>
            <div className={styles2.chapter}>
                <div className={styles2.chapterEntry}>
                    <div >
                        <div className={styles.bookHeader}>
                            {/* {!book.classPack.isWritingActivity && ( */}
                            <div className={styles.bookHeaderImg}>
                                <img alt='' src={book.bookTemplate.coverUrl || noCover} />
                            </div>
                            {/* )} */}
                            <div className={styles.bookHeaderMeta}>
                                <h3>{book.title || book.bookTemplate.name}</h3>
                                <div>
                                    {/* {!book.classPack.isWritingActivity && <h2 className="h1 heavy u-mb-2">By You and {book.studentsClass.name}</h2>} */}
                                    <h2 className="h1 heavy u-mb-2">By You and {book.studentsClass.name}</h2>

                                    <p className="u-mb-0">{book.studentsClass.school.name}</p>
                                    <p className="u-mb-0">{book.studentsClass.teachers[0] && book.studentsClass.teachers[0].displayName}</p>
                                </div>
                            </div>
                        </div>
                        {
                            completed && (
                                <Fragment>
                                    <h2 className="h1 heavy">Well done!</h2>
                                    <p>Your book is complete. Click on the winning chapters you would like to re-read. These will be the contents of your book.</p>
                                    <ul className={styles.bookChapters}>
                                        {book.chapters.map((chapter, i) => (
                                            <li key={`chapter-${i}`}><Button onClick={() => setSelectedChapter(chapter.orderid)} className={styles.bookInfoChaptersLink}>Chapter {i + 1}</Button></li>
                                        ))}
                                    </ul>
                                </Fragment>
                            )}
                    </div>
                </div>

                <div className={styles2.chapterSide}>
                    {
                        completed ? (
                            <div className={styles.bookCover}>
                                <img src={book.bookTemplate.coverUrl || noCover} alt={`Book cover for ${book.title || book.bookTemplate.name}`} />
                            </div>
                        ) : (
                            <>
                                <Guidance title={'Info'} text={null} textBlocks={getGuidanceText()} />
                            </>
                        )}
                </div>

            </div>

        </div>


    );
}

export default BookInfo;
