import React, { useState } from 'react';
import { Button, Modal } from '@axeedge/go-pupil-components';

import Header from '../Header';
import Guidance from '../Guidance';
import EditorJsOutput from '../../../../../../components/EditorJsOutput';
import { BOOK_STATUS } from '@axeedge/go-shared-utils';
import ReactPlayer from 'react-player';
import styles from '../../Chapter.module.scss';

const TeacherChapter = ({ bookStatus, chapter, setSelectedChapter, bookTemplate }) => {

    const [showVideo, setShowVideo] = useState(false);

    const getChapterContent = () => {
        try {
            JSON.parse(chapter.content);
        } catch (e) {
            return {
                blocks: [
                    {
                        type: 'paragraph',
                        data: {
                            text: chapter.content
                        }
                    }
                ]
            }
        }
        return JSON.parse(chapter.content)
    }
    return (
        <div className={styles.chapter}>
            <div className={styles.chapterEntry}>
                <div className={styles.chapterEntryContent}>
                    <Header title={chapter.title} />
                    <EditorJsOutput data={getChapterContent()} />
                </div>
            </div>
            <div className={styles.chapterSide}>
                <div className={styles.chapterSideSticky}>
                    <Guidance title='Instructions' text='Read this opening chapter. You and your class will continue the story by writing the next chapter.' />
                    {
                        !(bookStatus >= BOOK_STATUS.completed) && (
                            <div className={styles.chapterSideActions}>
                                <Button onClick={() => setSelectedChapter(1)} primary fullWidth>Go Write</Button>
                            </div>
                        )
                    }
                    {bookTemplate.videoUrl && (
                        <Button outline fullWidth className='u-mt-2' onClick={() => setShowVideo(true)}>Watch video guide</Button>
                    )}
                </div>
            </div>
            {showVideo && <Modal wide closeModal={() => setShowVideo(false)}>
                <div className="u-display-flex u-justify-center">
                    <ReactPlayer url={bookTemplate.videoUrl} />
                </div>
            </Modal>
            }
        </div>
    )
}

export default TeacherChapter;
