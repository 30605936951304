import React, { useState, Fragment, useContext } from 'react';
import { AlertCircle, XCircle, File, Eye } from 'react-feather';
import { Button } from '@axeedge/go-pupil-components';
import Modal from '../../../../../../components/Modal';
import { AuthContext } from '../../../../../../services/user/AuthProvider';
import boomer from '../../../../../../images/loader-boomer.png'
import ReactPlayer from 'react-player';
import cx from 'classnames';
import styles from './Guidance.module.scss';

const Guidance = ({ isFeedback, title, text, textBlocks = null, resources = null, videoUrl }) => {
    const [guidanceMobile, setGuidanceMobile] = useState(false);

    const [showVideo, setShowVideo] = useState(false);


    const toggleGuidanceInfo = () => {
        guidanceMobile ? setGuidanceMobile(false) : setGuidanceMobile(true);
    }

    const { currentUser: user } = useContext(AuthContext);

    const renderGuidance = () => {
        return (
            <Fragment>
                <h3 className={styles.guidanceTitle}><AlertCircle size="30" /> {title}</h3>
                {text && <p className={styles.guidanceContent}>{text}</p>}
                {textBlocks && <div className={styles.guidanceContent}>{textBlocks}</div>}
            </Fragment>
        )
    }

    return (
        <div className={cx(styles.guidance, { [styles.guidanceFeedback]: isFeedback })}>
            <div onClick={toggleGuidanceInfo} className={styles.guidanceInfoIcon}>
                <AlertCircle />
            </div>
            <div className={styles.guidanceAvatar}>
                <img src={user.boomerAvatarUrl ? `${user.boomerAvatarUrl}?v=${user.updatedAt.replace(/\s/g, '' )}` : boomer} className={styles.guidanceAvatarImg} />
            </div>
            <div className={styles.guidancePanel}>
                {renderGuidance()}
                {resources && resources.length > 0 &&
                    <>
                        <p>Check out the following resources</p>
                        {resources.map(res => {
                            return (
                                <div key={res.id || res.name} className={styles.taskFile}>
                                    {res.url.includes('amazon') && <a className={styles.taskFileView} href={res.url} rel="noopener noreferrer" target='_blank'>                                <File />
                                        <span className={styles.taskFileName}>{res.name}</span><Eye color="#9DC030" className={styles.taskFileEye} /></a>}
                                </div>
                            )
                        })}
                    </>
                }

                {videoUrl && (
                    <Button outline fullWidth className='u-mt-2' onClick={() => setShowVideo(true)}>Watch video guide</Button>
                )}
            </div>
        
            {
                guidanceMobile && (
                    <div className={styles.guidanceMobile}>
                        <div className={styles.guidanceMobileInner}>
                            <div onClick={toggleGuidanceInfo} className={styles.guidanceMobileClose}><XCircle size="30" /></div>
                            <div className={styles.guidanceMobileAvatarHolder}>
                                <div className={styles.guidanceMobileAvatar}>
                                <img src={user.boomerAvatarUrl ? `${user.boomerAvatarUrl}?v=${user.updatedAt.replace(/\s/g, '' )}` : boomer} className={styles.guidanceMobileAvatarImg}  />
                                </div>
                            </div>
                            <div className={cx(styles.guidancePanel, styles.guidanceMobilePanel)}>
                                {renderGuidance()}
                            </div>
                        </div>
                    </div>
                )
            }

            {showVideo && <Modal wide closeModal={() => setShowVideo(false)}>
                <div className="u-d-flex u-justify-center">
                    <ReactPlayer url={videoUrl} />
                </div>
            </Modal>
            }

        </div>
    )
}

export default Guidance;
