import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import EditorJs from 'react-editor-js';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { Lock } from 'react-feather';
import { useEffectOnce } from 'react-use';
import useTimeout from 'use-timeout';
import emojiStrip from 'emoji-strip';
import _ from 'lodash';

import { Button, FormErrors } from '@axeedge/go-pupil-components';
import { ENTRY_STATUS, CHAPTER_STATUS, ENTRY_WORD_LIMIT } from '@axeedge/go-shared-utils';
import BoomerModal from '../../../BoomerModal';
import EditorJsOutput from '../../../../../../components/EditorJsOutput';
import Guidance from '../Guidance';
import Header from '../Header';
import VocabModule from '../VocabModule';
import {
    HAND_IN_ENTRY_MUTATION,
    SAVE_DRAFT_MUTATION,
    GET_STUDENT_ENTRY
} from '../../../../services/graphql';

import { updateEntry, highlightEntry } from '../../../../services/utils';

import styles from '../../Chapter.module.scss';

const EMPTY_ENTRY = {
    blocks: []
}


const Writing = ({ chapter, resources = null, videoUrl }) => {
    const [isEditorReady, setIsEditorReady] = useState(false);
    useTimeout(() => setIsEditorReady(true), 100);


    const [confirmSubmit, setConfirmSubmit] = useState(false);

    const [formErrors, setFormErrors] = useState([]);
    const [entry, setEntry] = useState({
        text: JSON.parse(chapter.currentStudentEntry.text) || EMPTY_ENTRY,
        wordCount: 0
    });

    let interval;

    useEffectOnce(() => {
        if (chapter.currentStudentEntry.text) {
            updateEntry(entry.text, setEntry);
        }
    });

    const editorJsRef = useRef(null);
    const editorHolder = useRef(null);

    const client = useApolloClient();

    const [saveDraft, { loading: savingDraft }] = useMutation(SAVE_DRAFT_MUTATION, {
        onCompleted: ({ saveChapterEntry }) => {
            if (saveChapterEntry.errors && saveChapterEntry.errors.length !== 0) {
                setFormErrors(saveChapterEntry.errors);
                return;
            } else {
                client.writeQuery({
                    query: GET_STUDENT_ENTRY,
                    variables: {
                        id: saveChapterEntry.bookChapterStudentEntry.id
                    },
                    data: {
                        studentEntry: saveChapterEntry.bookChapterStudentEntry
                    }
                })
                /*if (editorJsRef.current && !_.isEmpty(editorJsRef.current)) {
                    editorJsRef.current.render(JSON.parse(saveChapterEntry.bookChapterStudentEntry.text));
                    editorJsRef.current.isReady.then(() => {
                        setTimeout(() => {editorJsRef.current.focus(true)}, 0);
                    })
                }*/
            }
        }

    })

    const [saveEntry, { loading: savingEntry }] = useMutation(HAND_IN_ENTRY_MUTATION, {
        onCompleted: ({ handInChapterEntry }) => {
            if (handInChapterEntry.errors && handInChapterEntry.errors.length !== 0) {
                setFormErrors(handInChapterEntry.errors);
                return;
            } else {
                client.writeQuery({
                    query: GET_STUDENT_ENTRY,
                    variables: {
                        id: handInChapterEntry.bookChapterStudentEntry.id
                    },
                    data: {
                        studentEntry: handInChapterEntry.bookChapterStudentEntry
                    }
                })
                setConfirmSubmit(false);
            }
        }
    })

    const onSubmitEntry = useCallback(async () => {
        const savedData = await editorJsRef.current.save();

        if (savedData.blocks.length !== 0) {

            savedData.blocks.forEach(function (_, i, blocks) {
                blocks[i].data.text = emojiStrip(blocks[i].data.text)
            });

            saveEntry({
                variables: {
                    bookChapterStudentEntryId: chapter.currentStudentEntry.id,
                    text: JSON.stringify(savedData)
                }
            })
        }
    }, []);

    const onSaveDraft = useCallback(async () => {
        const savedData = await editorJsRef.current.save();
        if (savedData.blocks.length !== 0 && JSON.stringify(savedData.blocks) !== JSON.stringify(entry.text.blocks) && !confirmSubmit) {

            savedData.blocks.forEach(function (_, i, blocks) {
                blocks[i].data.text = emojiStrip(blocks[i].data.text)
            });

            saveDraft({
                variables: {
                    bookChapterStudentEntryId: chapter.currentStudentEntry.id,
                    text: JSON.stringify(savedData)
                }
            })
        }
    }, [chapter.currentStudentEntry, confirmSubmit]);

    const onEditorChange = useCallback(async () => {
        const editorData = await editorJsRef.current.save();
        updateEntry(editorData, setEntry);
    })



    useEffect(() => {
        const handleClick = e => {
            if (editorHolder.current && !editorHolder.current.contains(e.target) && editorJsRef.current && editorJsRef.current.save) {
                onSaveDraft();
            }
        }
        window.addEventListener('mousedown', handleClick, false);
        return () => {
            window.removeEventListener('mousedown', handleClick, false);
        }
    }, [onSaveDraft]);

    useEffect(() => {
        if (!interval && !(chapter.status === CHAPTER_STATUS.writing_closed || chapter.status === CHAPTER_STATUS.voting_ready || chapter.currentStudentEntry.status === ENTRY_STATUS.submitted)) {
            interval = setInterval(() => {
                if ((!savingDraft && chapter.currentStudentEntry.status !== ENTRY_STATUS.submitted) && editorJsRef.current.save) {
                    onSaveDraft();
                };
            }, 60000);
        }

        return () => clearInterval(interval);
    }, [chapter, savingDraft, onSaveDraft, editorJsRef, interval]);

    useEffect(() => {
        if (interval && (chapter.status === CHAPTER_STATUS.writing_closed || chapter.status === CHAPTER_STATUS.voting_ready || chapter.currentStudentEntry.status === ENTRY_STATUS.submitted)) {
            clearInterval(interval)
        }
    }, [chapter, interval])



    const getGuidanceTitle = () => {
        if (chapter.currentStudentEntry.status === ENTRY_STATUS.submitted) {
            return 'Fantastic!';
        }
        if (chapter.currentStudentEntry.status === ENTRY_STATUS.revisionRequested) {
            return 'Feedback';
        }
        return 'Instructions';
    }

    const getGuidanceText = () => {
        if (chapter.currentStudentEntry.status === ENTRY_STATUS.submitted) {
            return 'Your work has now been sent to your teacher. Well done!';
        }
        if (chapter.currentStudentEntry.status === ENTRY_STATUS.revisionRequested) {
            return chapter.currentStudentEntry.feedback;
        }
        // return chapter.guidance ? chapter.guidance : (chapter.classBook.classPack.isWritingActivity ? chapter.description : 'No instructions added');
        return chapter.guidance ? chapter.guidance : 'No instructions added';

    }


    return (
        <Fragment>
            <div className={styles.chapter}>
                <div className={styles.chapterEntry}>
                    <div className={styles.chapterEntryContent}>
                        <Header title={chapter.title} author={chapter.currentStudentEntry.author.name} />
                        {
                            chapter.status === CHAPTER_STATUS.writing_closed || chapter.status === CHAPTER_STATUS.voting_ready || chapter.currentStudentEntry.status === ENTRY_STATUS.submitted ? (
                                <Fragment>
                                    {
                                        chapter.currentStudentEntry.text ?
                                            <EditorJsOutput data={highlightEntry(entry.text, chapter.vocabularyWords)} /> :
                                            <p>Sorry, you didn't write an entry for this chapter</p>
                                    }

                                </Fragment>
                            ) : (
                                <div ref={editorHolder} className='editor'>
                                    {
                                        isEditorReady && (
                                            <EditorJs
                                                data={entry.text}
                                                placeholder='Start writing here...'
                                                instanceRef={instance => (editorJsRef.current = instance)}
                                                enableReInitialize={false}
                                                minHeight='50'
                                                onChange={onEditorChange}
                                                logLevel='WARN'
                                            />
                                        )
                                    }
                                    {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                                </div>
                            )
                        }
                        {
                            entry.wordCount > ENTRY_WORD_LIMIT.danger ? (
                                <p className={styles.chapterWordsDanger}>Oops, You have written too much!</p>
                            ) : entry.wordCount >= ENTRY_WORD_LIMIT.warning && (
                                <p className={styles.chapterWordsWarning}>You have {ENTRY_WORD_LIMIT.danger - entry.wordCount} {ENTRY_WORD_LIMIT.danger - entry.wordCount === 1 ? 'word' : 'words'} left!</p>
                            )
                        }
                    </div>
                </div>

                <div className={styles.chapterSide}>
                    <Guidance videoUrl={videoUrl} isFeedback={chapter.currentStudentEntry.status === ENTRY_STATUS.revisionRequested} title={getGuidanceTitle()} text={getGuidanceText()} resources={resources} />
                    {
                        (chapter.status === CHAPTER_STATUS.writing_closed || chapter.status === CHAPTER_STATUS.voting_ready) && (
                            <div className={styles.writingClosed}>
                                <Lock className={styles.writingClosedIcon} size='50' />
                                <p className={styles.writingClosedText}>Writing Has Ended</p>
                            </div>
                        )
                    }
                    <VocabModule entry={entry.text} vocab={chapter.vocabularyWords} />

                    <div className={styles.chapterSideActions}>

                        {
                            (chapter.status !== CHAPTER_STATUS.writing_closed && chapter.currentStudentEntry.status !== ENTRY_STATUS.submitted) && (
                                <ul className={styles.chapterEntrySaveActions}>
                                    <li><Button onClick={() => setConfirmSubmit(true)} disabled={entry.wordCount > ENTRY_WORD_LIMIT.danger} primary className='u-mr-1'>Hand it in</Button></li>
                                    <li><Button onClick={onSaveDraft} outline disabled={savingDraft || entry.wordCount > ENTRY_WORD_LIMIT.danger}>{savingDraft ? 'Saving draft...' : 'Save as draft'}</Button></li>
                                </ul>
                            )
                        }
                    </div>
                </div>
            </div>
            {confirmSubmit && (
                <BoomerModal closeModal={() => setConfirmSubmit(false)}>
                    <div className='u-text-center'>
                        <h2>Are you sure?</h2>
                        <div className='u-mt-4'>
                            <Button onClick={onSubmitEntry} disabled={savingEntry} primary className='u-mr-2'>{savingEntry ? 'Saving entry...' : 'Yes, hand it in'}</Button>
                            <Button onClick={() => setConfirmSubmit(false)} outline>No</Button>
                        </div>
                    </div>
                </BoomerModal>
            )}
        </Fragment>
    )
}

export default Writing;
